import React, { useEffect, useState } from 'react'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { useUserProfile } from '../../contexts/UserProfile';
import { useParams } from 'react-router';
import { LOADING, SUCCESS } from '../../constants/common';
import { useNetwork } from '../../contexts/NetWork';

import MainLayout from '../../layouts/MainLayout'
import UserConnections from './UserConnections';
import { useSearchParams } from 'react-router-dom';
import SideComponents from './SideComponents';
import ProfileHeader from './ProfileHeader';
import ProfileContent from './ProfileContent';
import Gallery from './ProfileContent/components/Gallery';

const UserProfile = () => {
  const { fetchUserProfile, fetchStatus } = useUserProfile();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const account_type = searchParams.get('account_type');

  const { connectStatus, updateNetworkState } = useNetwork();
  const [showConnections, setShowConnections] = useState(false);
  const [showOthersConnections, setShowOthersConnections] = useState(false);

  useEffect(() => {
    fetchUserProfile({ uuid: id, account_type });
  }, [id, account_type])

  useEffect(() => {
    if (connectStatus === SUCCESS) {
      fetchUserProfile({ uuid: id, account_type });
      updateNetworkState('connectStatus', '')
    }
  }, [connectStatus])

  if (fetchStatus === LOADING)
    return null
  return (
    <MainLayout
      noGap
    >
      <ProfileHeader
        showConnections={showConnections}
        setShowConnections={setShowConnections}
        showOthersConnections={showOthersConnections}
        setShowOthersConnections={setShowOthersConnections}
      />
      <Container>
        {showConnections || showOthersConnections ? (
          <UserConnections other={showOthersConnections}/>
        ) : (
          <>
            <Gallery />
            <Grid container spacing={3.75}>
              <Grid item xs={4}>
                <SideComponents />
              </Grid>
              <Grid item xs={8}>
                <ProfileContent />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </MainLayout>
  )
}

export default UserProfile
import React, { useEffect, useState } from "react";
import {
  Paper,
  Box,
  Container,
  Typography,
  Avatar,
  Grid,
  TextField,
  IconButton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTeams } from "../../../contexts/Teams";
import { LOADING, SUCCESS } from "../../../constants/common";
import { useCommon } from "../../../contexts/common";
import {
  validateEmail,
  validateName,
  validatePassword,
} from "../../../helper/Validations";
import { zodResolver } from "@hookform/resolvers/zod";
import { invitedUserValidationSchema } from "../../../validations/registerValidation";
import { Controller, useForm, useWatch } from "react-hook-form";
import { InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Register = () => {
  const {
    organizationData,
    next,
    registerTeamMember,
    registerMemberStatus,
    updateTeamsState,
  } = useTeams();
  const { checkIfEmailExists, emailExists } = useCommon();
  const [isInvalid, setIsInvalid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const initialState = {
    first_name: "",
    last_name: "",
    password: "",
    email: "",
  };
  const [state, setState] = useState(initialState);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm({
    resolver: zodResolver(invitedUserValidationSchema),
    mode: "onChange",
    defaultValues: {
      first_name: state.first_name || "",
      last_name: state.last_name || "",
      email: state.email || "",
      password: state.password || "",
    },
  });

  const formValues = useWatch({ control });

  useEffect(() => {
    setState(formValues);
  }, [formValues]);

  useEffect(() => {
    if (registerMemberStatus === SUCCESS) {
      next();
      updateTeamsState("registerMemberStatus", "");
      setState(initialState);
    }
  }, [registerMemberStatus]);

  useEffect(() => {
    if (organizationData?.params?.email) {
      setState((prevState) => ({
        ...prevState,
        email: organizationData?.params?.email,
      }));
      setValue("email", organizationData?.params?.email);
    }
  }, [organizationData?.params?.email]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInput = (ev) => {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value,
    }));
  };

  const onSubmit = async () => {
    console.log("here");
    const response = await checkIfEmailExists(state.email);
    if (isValid && !Boolean(response.data.code)) {
      const payload = {
        first_name: state.first_name,
        last_name: state.last_name,
        password: state.password,
        team_id: organizationData?.params?.team_id,
        role: organizationData?.params?.role,
        url_id: organizationData?.id,
        invite_as_mental_health:
          organizationData?.params?.invite_as_mental_health,
      };
      if (organizationData?.params?.email) {
        payload.email = organizationData?.params?.email;
      } else {
        payload.email = state.email;
      }
      registerTeamMember(payload);
    } else setIsInvalid(true);
  };

  return (
    <Container maxWidth="sm">
      <Paper
        sx={{
          textAlign: "center",
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            backgroundColor: "primary.light",
            pt: 4,
            px: 4,
            pb: 3,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Typography variant="h1" maxWidth={386} mx="auto" mb={1}>
            Join {organizationData?.params?.program_name} on Word of Mouth
          </Typography>
          <Typography variant="body1" mb={3}>
            Save time. Be in the know. <br />
            Referrals made easy
          </Typography>
          <Avatar
            sx={{
              width: 60,
              height: 60,
              mx: "auto",
            }}
            src={organizationData?.params?.program_logo}
          />
        </Box>
        <Box
          sx={{
            pt: 3,
            pb: 4,
            px: 4,
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          {organizationData?.params?.email && (
            <Typography variant="body1">
              You're accepting an invitation sent to{" "}
              {organizationData?.params?.email}.
            </Typography>
          )}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label="First Name"
                    fullWidth
                    error={!!errors.first_name}
                    helperText={errors.first_name?.message}
                  />
                )}
              />
              {/* <TextField
                fullWidth
                label="First Name"
                onChange={handleInput}
                error={isInvalid && !validateName(state.first_name)}
                value={state.first_name}
                name="first_name"
                helperText={
                  isInvalid &&
                  !validateName(state.first_name) &&
                  "Please enter valid first name"
                }
              /> */}
            </Grid>
            <Grid item xs={6}>
              {/* <TextField
                fullWidth
                label="Last Name"
                onChange={handleInput}
                error={isInvalid && !validateName(state.last_name)}
                value={state.last_name}
                name="last_name"
                helperText={
                  isInvalid &&
                  !validateName(state.last_name) &&
                  "Please enter valid last name"
                }
              /> */}
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label="Last Name"
                    fullWidth
                    error={!!errors.last_name}
                    helperText={errors.last_name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <TextField
                fullWidth
                label="Email"
                type="email"
                onChange={handleInput}
                error={
                  Boolean(emailExists) ||
                  (isInvalid && (!state.email || !validateEmail(state.email)))
                }
                value={state.email}
                name="email"
                disabled={Boolean(organizationData?.params?.email)}
                helperText={
                  (Boolean(emailExists) &&
                    "Email already exists please choose another one") ||
                  (isInvalid &&
                    !validateEmail(state.email) &&
                    "Please enter valid email")
                }
              /> */}
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label="Email"
                    type="email"
                    fullWidth
                    value={organizationData?.params?.email}
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <TextField
                fullWidth
                label="Password"
                type="password"
                onChange={handleInput}
                error={isInvalid && !validatePassword(state.password)}
                value={state.password}
                name="password"
                helperText={
                  isInvalid &&
                  !validatePassword(state.password) &&
                  "Please enter valid password"
                }
              /> */}
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    fullWidth
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <LoadingButton
            size="large"
            fullWidth
            variant="contained"
            type="submit"
            loading={registerMemberStatus === LOADING}
          >
            Continue
          </LoadingButton>
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              maxWidth: 300,
              mx: "auto",
              textAlign: "center",
              mt: 2,
              fontWeight: "bold",
            }}
          >
            By signing up, you agree to the{" "}
            <a
              sx={{ color: "#0C8CE9 !important" }}
              href={"https://www.therapywordofmouth.com/terms"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a
              sx={{ color: "#0C8CE9 !important" }}
              href={"https://www.therapywordofmouth.com/privacy"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default Register;

import { axiosInstance } from "./axiosInstance";

const getNetwork = (params) => {
  return axiosInstance.get('network', {
    params
  });
}

const getConnections = (params) => {
  return axiosInstance.get('connections', { params });
}

const getNetworkConnections = (params) => {
  return axiosInstance.get('connections/list', { params });
}

const getOtherConnections = (params) => {
  return axiosInstance.get('connections/list-connected', { params });
}


const deleteConnection = (id, userType) => {
  return axiosInstance.delete(`connections/${id}?account_type=${userType}`);
}


const getFavorites = (params) => {
  return axiosInstance.get('favorites', { params });
}

const connectUser = (data) => {
  return axiosInstance.post('connections/connect', data);
}
export {
  getNetwork,
  getConnections,
  getNetworkConnections,
  getOtherConnections,
  getFavorites,
  connectUser,
  deleteConnection
}
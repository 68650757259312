import React, { createContext, useContext, useState } from 'react';
import { ERROR, LOADING, SUCCESS } from '../constants/common';
import { useAlert } from './Alert';
import ClientsServedDrawer from '../components/ClientsServedDrawer';
import { 
  getClientTypes, 
  getSelectedCurrentlyServed, 
  getUserClientTypes, 
  updateAgesServed, 
  updateClientTypes, 
  updateCurrentlyServed 
} from '../services/clientTypes.services';


const ClientServedContext = createContext({});

export const ClientServedContextProvider = ({ children }) => {
  const { sendAlert } = useAlert();
  const [stateData, setStateData] = useState({
    clientTypes: [],
    currently_served: [],
    fetchClientTypesStatus: '',
    updateClientTypesStatus: '',
    userClientTypes: [],
    userCurrentlyServed: [],
    fetchUserClientTypesStatus: '',
    editAgesServedStatus: '',
  });

  const [openClientsServed, setClientsServedOpen] = useState(false);

  function handleOpenClientsServedDrawer() {
    setClientsServedOpen(true)
  }

  function handleCloseClientsServedDrawer() {
    setClientsServedOpen(false)
  }


  function handleState(obj) {
    setStateData((prevState) => ({
      ...prevState,
      ...obj
    }))
  }


  async function fetchClientTypes(data) {
    handleState({ fetchClientTypesStatus: LOADING })
    try {
      const response = await getClientTypes(data);
      handleState({
        clientTypes: response.data.data,
        fetchClientTypesStatus: SUCCESS
      })
    } catch (error) {
      handleState({ fetchClientTypesStatus: ERROR })
      Promise.reject(error)
    }
  }

  async function fetchCurrentlyServed() {
    handleState({ fetchClientTypesStatus: LOADING })
    try {
      const response = await getSelectedCurrentlyServed();
      handleState({
        currently_served: response.data.data,
        fetchClientTypesStatus: SUCCESS
      })
    } catch (error) {
      handleState({ fetchClientTypesStatus: ERROR })
      Promise.reject(error)
    }
  }

  async function editClientTypes(data) {
    handleState({ updateClientTypesStatus: LOADING })
    try {
      await updateClientTypes(data);
      handleState({
        updateClientTypesStatus: SUCCESS
      })
      sendAlert('Updated client types successfully', 'success')
    } catch (error) {
      handleState({ updateClientTypesStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  async function editCurrentlyServed(data) {
    handleState({ updateClientTypesStatus: LOADING })
    try {
      await updateCurrentlyServed(data);
      handleState({
        updateClientTypesStatus: SUCCESS
      })
      sendAlert('Updated client types successfully', 'success')
    } catch (error) {
      handleState({ updateClientTypesStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  async function fetchUserClientTypes(data) {
    handleState({ fetchUserClientTypesStatus: LOADING })
    try {
      const response = await getUserClientTypes(data);
      handleState({
        userClientTypes: response.data.data,
        fetchUserClientTypesStatus: SUCCESS
      })
    } catch (error) {
      handleState({ fetchUserClientTypesStatus: ERROR })
      Promise.reject(error)
    }
  }

  async function fetchUserCurrentlyServed() {
    handleState({ fetchUserClientTypesStatus: LOADING })
    try {
      const response = await getSelectedCurrentlyServed();
      handleState({
        userCurrentlyServed: response.data.data,
        fetchUserClientTypesStatus: SUCCESS
      })
    } catch (error) {
      handleState({ fetchUserClientTypesStatus: ERROR })
      Promise.reject(error)
    }
  }

  async function editAgesServed(data, userType) {
    handleState({ editAgesServedStatus: LOADING })
    try {
      const response = await updateAgesServed(data, userType);

      handleState({
        editAgesServedStatus: SUCCESS
      })
      sendAlert('Updated Ages Served successfully', 'success')

      return response;
    } catch (error) {
      handleState({ editAgesServedStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  function updateClientTypesState(field, value) {
    handleState({ [field]: value })
  }


  const state = {
    ...stateData,
    fetchClientTypes,
    fetchCurrentlyServed,
    editClientTypes,
    editCurrentlyServed,
    fetchUserClientTypes,
    fetchUserCurrentlyServed,
    handleOpenClientsServedDrawer,
    editAgesServed,
    updateClientTypesState,
  };

  return (
    <ClientServedContext.Provider value={state}>
      {children}

      <ClientsServedDrawer
        open={openClientsServed}
        onClose={handleCloseClientsServedDrawer}
      />
    </ClientServedContext.Provider>
  );
};

export const useClientServed = () => useContext(ClientServedContext);
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Avatar, Button, Typography } from "@mui/material";
import UploadInput from "../../../assets/imgs/upload-input.png";
import { useBasicInfo } from "../../../contexts/BasicInfo";
import { SUCCESS, domain } from "../../../constants/common";
import { useAuth } from "../../../contexts/Auth";
import Cookies from "universal-cookie";
import { useTeams } from "../../../contexts/Teams";
import { useAlert } from "../../../contexts/Alert";
import { imageTypes } from "../../../constants/lists";

const CompleteProfile = () => {
  const { editProfilePhoto, updatePhotoStatus, updateInfoState } =
    useBasicInfo();
  const { updateAuthState, userData } = useAuth();
  const { organizationData } = useTeams();
  const [error, setError] = useState("");
  const [profileImageURL, setProfileImageURL] = useState(null);
  const cookies = new Cookies();
  const { sendAlert } = useAlert();
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 1);

  useEffect(() => {
    if (updatePhotoStatus === SUCCESS) {
      updateInfoState("updatePhotoStatus", "");
      handleGoToLogin();
    }
  }, [updatePhotoStatus]);

  const handleFileRead = (file) => {
    if (!file || !file.type?.startsWith("image/")) {
      setProfileImageURL(null);
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      setProfileImageURL(event.target.result);
    };
    reader.onerror = () => {
      console.error("Error reading the file.");
      sendAlert("There is an issue while reading this file", "error");
    };
    reader.readAsDataURL(file);
  };

  const handleFile = (file) => {
    if (!file) {
      setProfileImageURL(null);
      return;
    }

    if (file.size > 10 * 1024 * 1024) {
      setError({ message: "The logo must be less than 10 MB." });
      setProfileImageURL(null);
      return;
    }

    if (!imageTypes.includes(file.type)) {
      setError({
        message: "Please upload a valid image file.\n(jpeg, jpg, png, svg+xml)",
      });
      setProfileImageURL(null);
      return;
    }

    if (!file.type.startsWith("image/")) {
      setProfileImageURL(null);
      setError({ message: "Please select a valid image file." });
      return;
    }
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const width = img.width;
      const height = img.height;

      if (width < 300 || height < 300) {
        setError({
          message: "The image dimensions should be at least 300x300 pixels.",
        });
        setProfileImageURL(null);
        return;
      }

      if (width > 2000 || height > 2000) {
        setError({
          message: "The image dimensions should not exceed 2000x2000 pixels.",
        });
        setProfileImageURL(null);
        return;
      }
      setError("");
      handleFileRead(file);
    };
    img.onerror = () => {
      setError({
        message:
          "There was an error loading the image. Please select a valid image file.",
      });
      setProfileImageURL(null);
      return;
    };
  };

  const handleFileInput = async (event) => {
    const file = event.target.files?.[0];
    handleFile(file);
  };

  const handleSaveImage = () => {
    if (profileImageURL) editProfilePhoto(profileImageURL);
  };

  const handleGoToLogin = () => {
    localStorage.setItem("isLoggedIn", "true");
    updateAuthState("isLoggedIn", true);
    cookies.set("isLoggedIn", "true", {
      expires: expirationDate,
      domain: `.${domain}`,
      secure: true,
    });
    setProfileImageURL(null);
  };

  const renderRole = () => {
    if (organizationData?.params?.role === "team_admin") return "Admin";
    if (organizationData?.params?.role === "team_assistant") return "Assistant";
    return "";
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const { files } = event.dataTransfer;
    handleFile(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <Container maxWidth="sm">
      <Paper
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 3,
          borderRadius: 2,
        }}
      >
        <Typography variant="h3" fontWeight={600}>
          Add a profile photo
        </Typography>
        <Box
          sx={{
            border: "1px solid",
            borderColor: "secondary.main",
            borderRadius: 2,
            backgroundColor: "background.default",
            p: 4,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
            cursor: "pointer",
          }}
          component="label"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <Box component="img" src={UploadInput} width={124} height={140} />
          <Typography variant="body2">Drag your photo here, or...</Typography>
          {error?.message && (
            <Typography variant="caption" color="error" className="p-5">
              {error.message}
            </Typography>
          )}
          <Button
            color="secondary"
            variant="outlined"
            sx={{
              color: "primary.main",
            }}
            component="span"
            disableRipple
          >
            Browse Files
          </Button>
          <Box
            component="input"
            sx={{ display: "none" }}
            type="file"
            accept="image/*"
            onChange={handleFileInput}
          />
        </Box>
        <Box>
          <Typography
            variant="captionTitle"
            mb={2}
            component="p"
            fontWeight={600}
          >
            Preview
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                width: 60,
                height: 60,
              }}
              src={profileImageURL}
            />
            <Box>
              <Typography variant="body2" fontWeight={600}>
                {userData.first_name} {userData.last_name}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {organizationData?.params?.program_name} I {renderRole()}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <Button
            color="secondary"
            variant="outlined"
            sx={{
              color: "primary.main",
              minWidth: 90,
            }}
            component="span"
            onClick={handleGoToLogin}
          >
            Skip
          </Button>
          <Button
            variant="contained"
            sx={{
              minWidth: 90,
            }}
            onClick={handleSaveImage}
            disabled={!profileImageURL}
          >
            Save
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default CompleteProfile;

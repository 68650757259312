const paths = {
    home: '/',
    Notifications: '/notifcations',
    network: (id = ':id') => `/network/${id}`,
    post: `/post`,
    referrals: (id = ':id') => `/referrals/${id}`,
    profileBasic: '/profile/basic',
    profileDegree: '/profile/degree',
    profileAvailability: '/profile/availability',
    profileDemographics: '/profile/demographics',
    profileSpecialties: '/profile/specialties',
    profileTreatment: '/profile/treatment-modalities',
    clientsServed: '/profile/client-focus',
    profileInterests: '/profile/interests',
    profileFees: '/profile/fees',
    profileLanguages: '/profile/languages',
    profileCredentials: '/profile/credentials',
    profileTraining: '/profile/training',
    profileOrganization: '/profile/organization',
    profileAccountSettings: '/profile/account-settings',
    profilePasswordSettings: '/profile/password-settings',
    profileTeam: '/profile/team',
    profileRoles: '/profile/roles',
    profilePayments: '/profile/payments',
    profileBilling: '/profile/billing',
    profileServices: '/profile/services',
    profileFaqs: '/profile/faqs',
    profileImages: '/profile/images',
    login: '/login',
    onboarding: '/onboarding',
    forgetPassword: '/forget-password',
    resetPassword: '/reset-password',
    userProfile: (id = ':id', account_type = '') => `/user-profile/${id}/${account_type && `?account_type=${account_type}`}`,
    settingsAccount: '/settings/account',
    settingsPassword: '/settings/password',
    settingsInvitations: '/settings/Invitations',
    settingsNotifications: '/settings/Notifications',
    invitationOnboarding: (url = ":url") => `/teams/acceptInvite/${url}`,
    info: '/info',
    changePassword: '/change-password'
};

export default paths;
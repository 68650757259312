import { axiosInstance } from './axiosInstance';

const getUserClientTypes = (params) => {
  return axiosInstance.get('client-types/user', { params });
}

const getClientTypes = (params) => {
  return axiosInstance.get('client-types/with-selection', { params });
}

const getAllCurrentlyServed = () => {
  return axiosInstance.get('currently-served/getAll');
}

const getSelectedCurrentlyServed = () => {
  return axiosInstance.get('currently-served/cs-with-selection');
}

const updateClientTypes = (data) => {
  return axiosInstance.post('client-types', data);
}

const updateCurrentlyServed = (data) => {
  return axiosInstance.post('currently-served/add-cs', data);
}

const updateAgesServed = (data, userType = 'info') => {
  return axiosInstance.put(`${userType}/age-served`, data);
}

export {
  getUserClientTypes,
  getClientTypes,
  updateClientTypes,
  updateAgesServed,
  getAllCurrentlyServed,
  getSelectedCurrentlyServed,
  updateCurrentlyServed
}
import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from '@mui/lab/LoadingButton';

import { LOADING, SUCCESS, treatment_program } from '../../constants/common';
import CheckboxListItem from '../CheckboxListItem';
import { useBasicInfo } from '../../contexts/BasicInfo';
import { useClientServed } from '../../contexts/ClientServed';
import { useAuth } from '../../contexts/Auth';
import { useGeneralProfile } from '../../contexts/Organization';

const ClientsServedDrawer = ({ onClose, open }) => {
  const {
    updateClientTypesState,
    fetchClientTypes,
    clientTypes,
    fetchClientTypesStatus,
    editClientTypes,
    updateClientTypesStatus,
    fetchUserClientTypes
  } = useClientServed();

  const { fetchFacilityInfo, facilityInfo } = useGeneralProfile();
  const { fetchInfo,  data } = useBasicInfo();
  const [selectedClientTypes, setSelectedClientTypes] = useState([]);
  const [error, setError] = useState('');
  const { account_type } = useAuth();
  const info = account_type !== treatment_program ? data : facilityInfo;

  const handleClose = () => {
    setError('');
    onClose();
  }

  const selectCurrentlyServed = (data) => {
    setSelectedClientTypes((prevState) => {
      const exists = !!prevState?.find((item) => +item === data.id);
      if (exists) {
        return (
          prevState.filter((item) => +item !== data.id)
        )
      }
      return (
        [
          ...prevState,
          data.id
        ]
      )
    })
  }

  const saveCurrentlyServed = () => {
    if (!selectedClientTypes?.length) {
      setError('Please select one or more client type.');
      return;
    }
    editClientTypes({
      populations: selectedClientTypes,
      account_type: account_type === treatment_program ? treatment_program : 'user'
    })
    setError('');
  }

  // const disabled = () => {
  //   return selectedClientTypes?.length < 1
  // }

  useEffect(() => {
    if (open) {
      fetchClientTypes({ account_type: account_type === treatment_program ? treatment_program : 'user' });
    }
  }, [open])


  useEffect(() => {
    if (updateClientTypesStatus === SUCCESS) {
      updateClientTypesState('updateClientTypesStatus', '');
      fetchUserClientTypes({ account_type: account_type === treatment_program ? treatment_program : 'user' });
      account_type !== treatment_program ? fetchInfo() : fetchFacilityInfo();
      handleClose();
    }
  }, [updateClientTypesStatus, handleClose])

  useEffect(() => {
    setSelectedClientTypes(info?.client_types?.reduce((acc, item) => {
      if (item.is_active) {
        return [...acc, item.id.toString()]
      }
      return acc;
    }, []))
  }, [clientTypes])

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Client Types</Typography>
          <Typography variant='body1' mb={1}>Please select the types of clients you work with</Typography>

          <>
            <Divider sx={{ mb: 1 }} />
            <Box>
              <Grid container spacing={2.5} mb={2}>
                {fetchClientTypesStatus === LOADING ? (
                  Array.from({ length: 6 }).map((_, index) => (
                    <Grid item xs={6} key={index}>
                      <Skeleton animation='wave' />
                    </Grid>
                  ))
                ) : (
                  clientTypes.map((item) => (
                    <CheckboxListItem
                      key={item.id}
                      data={item}
                      selectItem={selectCurrentlyServed}
                      selectedItems={selectedClientTypes}
                    />
                  ))
                )}
              </Grid>
            </Box>
          </>
          {error && (!selectedClientTypes || !selectedClientTypes.length) && (
            <Typography variant="caption" color="error">
              {error}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            onClick={saveCurrentlyServed}
            loading={[updateClientTypesStatus].includes(LOADING)}
          // disabled={disabled()}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default ClientsServedDrawer;